<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ alias: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ alias: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ alias: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new email origin</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new email origin</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <!-- <template v-if="website">
                                <p>This form will be linked to the website
                                    <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: website.id } }">{{ website.label }}</router-link>.
                                    You can update the website content from this form.</p>
                            </template> -->
                            <!-- <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <p v-bind="attrs" v-on="on">What is a form alias? <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text text-lighten-2"/></p>
                                </template>
                                <p>When you create a form, we generate a unique identifier for it. When a form is attached to a server, it is generally accessible at <span class="text-no-wrap">/mnt/form/&lt;id&gt;</span>. When you access the storage form via file server, we create a convenient link from your home directory to the form mount point at <span class="text-no-wrap">~/&lt;account-alias&gt;/&lt;form-alias&gt;</span>. This makes it easier to recognize and access forms that you have access to. A form alias must be unique within the account.</p>
                            </v-tooltip>
                            <v-text-field
                                ref="formAliasInput"
                                v-model="newFormAlias"
                                label="Form alias"
                                :rules="newFormAliasRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                hint="The directory name to use for the form (you can change this later)"
                                type="text"
                                outlined
                            >
                            </v-text-field> -->
                            <p>
                                The "origin" appears as the "from" address in your outbound emails.
                                You can change it at any time, and it will automatically be updated in templates
                                that refer to it and applied to future emails sent using those templates.
                            </p>
                            <!-- TODO: require origin domain to be a verified domain already added to the account -->
                            <p>
                                The origin email address must use a verified domain that you control.
                            </p>
                            <!-- TODO: should we ask user for mailbox and domain input separately? then we can show list of domains they have; or if we ask for entire email address, we need to parse it to highlight if the domain they entered is in their account or not... -->
                            <v-row no-gutters align="center">
                                <v-text-field
                                    ref="emailOriginMailboxInput"
                                    v-model="newEmailOriginMailbox"
                                    label="Mailbox"
                                    :rules="newEmailOriginMailboxRules"
                                    validate-on-blur
                                    color="teal darken-2"
                                    required
                                    type="text"
                                    outlined
                                    dense
                                >
                                </v-text-field>
                                <!-- <div class="mb-3"><p>@</p></div> -->
                                <v-select outlined dense :items="newEmailOriginDomainChoices" v-model="newEmailOriginDomain" color="teal darken-2" label="Domain" required hint="A verified domain that you control">
                                    <template #prepend>
                                        @
                                    </template>
                                    <!-- <template #append-outer>
                                        <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                            <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                        </v-btn>
                                    </template> -->
                                </v-select>
                            </v-row>

                            <p class="mb-6">
                                Don't see the domain you want to use?
                                <router-link :to="{ name: 'account-create-domain', params: { accountId: this.$route.params.accountId } }">Add a domain...</router-link>
                            </p>

                            <v-text-field
                                ref="emailOriginDisplayNameInput"
                                v-model="newEmailOriginDisplayName"
                                label="Display name"
                                :rules="newEmailOriginDisplayNameRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';

export default {
    data: () => ({
        domainList: [],

        error: null,
        website: null,
        // create form
        createEmailOriginForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newEmailOriginMailbox: null,
        newEmailOriginMailboxRules: [
            (v) => !!v || 'Mailbox is required',
        ],
        newEmailOriginDomain: null,
        newEmailOriginDomainChoices: [], // will be loaded from server
        newEmailOriginDisplayName: null,
        newEmailOriginDisplayNameRules: [
            (v) => !!v || 'Display name is required',
        ],
        // // TODO: add property for replyto checkbox
        // newEmailOriginReplyTo: null,
        // newEmailOriginReplyToRules: [
        //     // (v) => !!v || 'Mailbox is required',
        //     (v) => isValidEmail(compact(v)) || 'Reply-to must be a valid email address',
        // ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newEmailOriginMailbox === 'string' && this.newEmailOriginMailbox.trim().length > 0 && typeof this.newEmailOriginDomain === 'string' && this.newEmailOriginDomain.trim().length > 0 && isValidEmail(`${this.newEmailOriginMailbox}@${this.newEmailOriginDomain}`);
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomainList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.search({ status: 'verified' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.domainList = response.list;
                    this.newEmailOriginDomainChoices = response.list.map((item) => ({
                        text: item.name, // TODO: if domain record shows the domain has DMARC/DKIM/SPF already set up, maybe we show a checkmark or some other icon next to the name on the list?
                        value: item.name,
                    }));
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newEmailOriginLabel === null || this.newEmailOriginLabel.length === 0) {
        //                 this.newEmailOriginLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEmailOrigin();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createEmailOrigin() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEmailOrigin: true });
                console.log('createEmailOrigin');
                const request = {
                    mailbox: this.newEmailOriginMailbox,
                    domain: this.newEmailOriginDomain,
                    label: this.newEmailOriginDisplayName,
                };
                // if (this.website) {
                //     request.website_id = this.website.id;
                // }
                const response = await this.$client.account(this.$route.params.accountId).emailOrigin.create(request);
                console.log('createEmailOrigin response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-email-origin', params: { accountId: this.$route.params.accountId, emailOriginId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email origin' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email origin' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create email origin', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create email origin' });
            } finally {
                this.$store.commit('loading', { createEmailOrigin: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomainList();
        // if (this.$route.query.website_id) {
        //     this.loadWebsite(this.$route.query.website_id);
        // }
    },
};
</script>
